.App {
  font-family: Arial, sans-serif;
  line-break: auto;
}

body {
  margin: 0;
}

.thread {
  border: 1px solid black;
  padding: 15px;
  margin: 10px;
}
.post {
  border: 1px solid black;
  padding: 15px;
  margin: 10px;
}

.hotTopics:hover {
  box-shadow: -1px 3px 3px 0px rgba(82, 25, 216, 0.158);
}

.thread-list:hover {
  background-color: rgba(0, 0, 0, 0.035);
}

.bookmark-list:hover {
  background-color: rgba(0, 0, 0, 0.035);
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

#subButton {
  width: 200px;
  height: 80px;
  border: none;
  outline: none;
  background: #2f2f2f;
  color: #fff;
  font-size: 22px;
  border-radius: 40px;
  text-align: center;
  box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
#stempelButton {
  width: 200px;
  height: 40px;
  border: none;
  outline: none;
  background: #2f2f2f;
  color: #fff;
  font-size: 22px;
  border-radius: 40px;
  text-align: center;
  box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.check-box {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  box-shadow: 0 0 12px -2px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: -40px;
  opacity: 0;
}

.check-box svg {
  width: 40px;
  margin: 20px;
}

.Header {
  background: rgb(200, 200, 205);
}

.monteurList {
  white-space: normal;
}

.sticky {
  position: sticky;
  top: 7%;
  z-index: 1000;
}
